/* Web View Start */

.navbar-container {
    height: 3.5rem;
    background: #808185;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-logo-container {
    display: flex;
    padding: 5px 70px;
    align-items: center;
  }
  
  .navbar-logo {
    width: 200px;
    height: 80px;
    padding: 8px 0;
  }
  
  .nav-menu {
    list-style-type: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin: 0;
  }
  
  /* .nav-item a {
    line-height: 22px;
    text-transform: capitalize;
    letter-spacing: 0px;
    opacity: 1;
    font-weight: 550;
    padding: 15px 20px;
    font-size: 15px !important;
    color: #fff;
    text-decoration: none;
  } */
  
  .navigation-menu ul {
    display: none;
  }
  
  /* .dropdown {
    position: relative;
    display: inline-block;
  } */
  
  .dropdown-content {
    display: none;
    height: 32rem;
    width: 14rem;
    padding: 5px 0 !important;
    margin: 0px !important;
    position: absolute;
    background-color: #1487C6;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    color: black;
    left: -10px;
    top: 10px;
  }

  .headers .nav-link {
    /* margin: 0px 20px; */
    margin: 0px 10px;
    /* color: black; */
    color: #1487C6;
    /* font-size: 20px; */
    font-size: 16px;
    font-weight: 700;
    /* font-family: "Piazzolla", serif; */
  }
  .navbar-dispay{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  
  .navbar-left-icons {
    text-align: center;
    align-items: center;
    display: flex;
  }
  
  .download-button {
    background: orange;
    text-align: center;
    align-items: center;
    display: flex;
    border: 1px solid white;
    color: black;
    height: 45px;
    padding: 5px 10px;
    margin: auto 0px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .download-button p {
    margin: auto;
    color: white;
    font-weight: bold;
  }
  
  
  .download-icon {
    width: 30px;
    height: 25px;
    margin: auto;
  }
  
  .call-icon {
    width: 40px;
    height: 40px;
    color: white;
  }
  
  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 46%;
    right: 20px;
    transform: translateY(-50%);
    display: none;
  }
  
  .hamburger:hover {
    background-color: #730b92;
  }
  
  .nav-icon {
    display: none;
  }
  
  .left-icons-container {
    height: 3rem;
    display: flex;
    float: right;
    margin: 10px 70px;
  }
  
  .icon-container {
    width: 50px;
    padding: 0 20px;
    cursor: pointer;
  }
  
  .nav-menu ul {
    margin: auto 0px !important;
  }
  
  /* .nav-item :hover {
    font-weight: 550 !important;
    color: #000 !important;
  } */
  
  /* .nav-item a:hover {
    font-weight: 400;
    color: #fd640c;
  } */
  
  .nav_000 {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #808185;
    padding: 6px !important;
    border-radius: 75px 0px 0px 0px;
    height: 4rem;
  }
  
  .dropdown-content p {
    color: white;
  }
  
  .enquiry-btn {
    background-color: #d9c90b;
    padding: 4px 6px !important;
    border-radius: 5px;
  }
  
  
  
  /* Web View End */
  
  
  
  /* Mobile View Start */
  
  @media screen and (max-width: 550px) {
    .navbar-left-icons {
      display: none;
    }
  
    .hamburger {
      display: block;
    }
  
    .nav-icon {
      display: block;
      font-size: 2.8rem;
      color: #0b0b09;
      background-color: rebeccapurple;
    }
  
    .nav-menu {
      display: none;
    }
  
    .navbar-container {
      display: flex;
      justify-content: flex-start !important;
    }
  
    .nav-logo-container {
      padding: 0 10px !important;
    }
  
    .navigation-menu ul {
      flex-direction: column;
      width: -webkit-fill-available;
      background-color: #f4f8f4;
      border: 1px solid black;
      display: none;
      height: fit-content;
      padding: 10px;
      position: absolute;
      width: 30%;
      left: 290px;
      top: 60px;
    }
  
    /* .dropdown-content {
      width: 6rem;
      height: 11rem;
      padding: 0;
    } */
  
    .navigation-menu li {
      text-align: center;
      margin: 5px;
    }
  
    .navigation-menu li a {
      color: black;
      padding: 1.5rem 0;
      text-decoration: none;
    }
  
    .navigation-menu.expanded ul {
      display: block;
      list-style: none;
      padding: 10px 20px !important;
      position: relative;
      left: 170px;
      z-index: 99999;
      width: 100%;
    }
    .navbar-logo {
      width: 150px;
      height: 100px;
  }
}
  
  /* Mobile View End */