/* Web view Start */

.contcat-banner-logo-section {
  display: flex;
  background: #1487c6;
  color: white;
  height: 167px;
  font-family: sans-serif !important;
}

.contact-banner-logo {
  width: 100%;
  height: 225px;
}

.contact-cards-container {
  padding: 0 50px;
  display: flex;
  justify-content: space-between !important;
}

.address-title-section {
  display: flex;
  padding: 5px 10px;
  gap: 18px;
  align-items: center;
}

.address-title-section h5 {
  color: #1487c6;
}

.contact-cards {
  display: flex;
  gap: 100px;
  position: relative;
  bottom: 60px;
  text-align: left;
}

.contact-display {
  flex: 1;
}

.contact-form-section {
  display: flex;
  padding: 0px 100px;
  flex: 2;
}

.contact-first-title {
  font-size: 22px;
  font-weight: 500;
  padding: 20px 0 0 0;
}

.contact-main-title {
  font-weight: 500;
  font-family: system-ui;
  padding: 30px 100px;
}

.form-container {
  width: 100%;
}

.banner-logo {
  width: 100%;
  height: 300px;
}

.discount {
  background: linear-gradient(45deg, #d10f30, yellow);
  color: white;
  height: 20px;
  text-align: center;
  align-items: center;
  margin: 0px 20px;
  position: absolute;
  right: 0;
  font-weight: 500;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
input[type="password"],
select,
textarea {
  width: 100%;
  border: 0.3px solid #bdc3c7;
  box-sizing: border-box;
  resize: vertical;
}

.send-button {
  padding: 8px 14px;
  margin: 20px 0;
  border: none;
  background: #1487c6 !important;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 20px;
}

.address-section {
  padding: 20px 70px;
  text-align: left;
}

.address-section h3 {
  color: orange;
  font-size: 20px;
}

.address-section h5 {
  font-size: 18px;
  line-height: 22px;
}

.contact-s-icon {
  display: flex;
  gap: 6px;
}

.FAQ__get__started__email {
  text-align: center;
  padding-bottom: 25px;
}

.FAQ__get__started__email > h3 {
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 0;
}

.faq {
  text-align: center;
  font-size: 18px;
  background: rgb(245 245 245);
}

.faq h2 {
  font-size: 22px;
  font-weight: 500;
  padding: 21px 0 0 0;
}

.accordian {
  margin: 20px auto;
  width: 100%;
  max-width: 750px;
}

.accordian li {
  list-style: none;
  width: 100%;
  padding: 5px;
}

.accordian li label {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  background: #1487c6;
  color: white;
}

.accordian li label::after {
  content: "+";
  font-size: 34px;
  position: absolute;
  right: 20px;
  transition: transform 0.5s;
}

input[type="radio"] {
  display: none;
}

.accordian .content {
  text-align: left;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s;
}

.accordian input[TYPE="radio"]:checked + label + .content {
  max-height: 600px;
  padding: 30px 20px;
}

.accordian input[TYPE="radio"]:checked + label::after {
  transform: rotate(135deg);
}

/* Web view End */

/* Mobile View Start */

@media screen and (max-width: 799px) {
  .contact-cards {
    display: block;
  }

  .contact-cards-container {
    margin: auto;
    padding: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .contact-form-section {
    display: block;
    padding: 10px;
  }

  .form-container {
    width: 100% !important;
  }

  .contact-main-title {
    font-size: 18px;
    padding-top: 50px;
  }
  .card {
    /* width:20rem !important;
      height:202px !important */
    width: 100% !important;
    height: auto !important;
    margin: 5px 0;
  }
}

@media screen and (max-width: 1024px) {
  .contact-cards-container {
    padding: 0px 5px;
  }

  .contact-cards {
    gap: 26px;
  }

  .contact-main-title {
    font-size: 22px;
    padding-top: 50px;
  }

  .contact-form-section {
    padding: 0px 5px;
  }
}

/* Mobile View End */
