.carousel-control-prev-icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.3s ease-in-out !important;
}
.text-typer-animation {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #1487c6; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

.about-display {
  display: flex;
  padding: 20px 0;
  gap: 30px;
}

.about-content {
  flex: 2;
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
.cardImage {
  width: 100%;
  transition: transform 0.5s ease;
}
.cardImage:hover {
  transform: scale(1.01);
  cursor: pointer;
  content-fit: cover;
}

.viewAllProducts {
  background: linear-gradient(
    90deg,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  border: unset;
  border-radius: unset;
}
.first-box {
  background-color: navy;
  padding: 20px 30px;
}

.first-box p{
  color: white;
  font-family: Roboto, sans-serif;
  line-height: 28px;
  font-size: 18px;
  text-align: left;
}
.homeimg{
  width: 100%;
  height: 450px;
  border: 3px solid #010161;
}

.counts_bg{
  background-image: url("../../../public/enquire-img.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  height: 562px;
  background-repeat: no-repeat;
  position: relative;
  left: -3px;
  box-sizing: border-box;
  max-width: 100vw;
  padding-left: 3px;
  padding-right: 20px;
  margin-top: 3rem;
}

/* @media only screen and (min-width: 0px) and (max-width: 600px) {
  .Header .header {
    display: none !important;
  }
  .navbarrrrr {
    top: unset !important;
  }
  .carousel-item img {
    height: 220px !important;
  }
  .title {
    font-size: 20px !important;
  }
  .text-typer-animation {
    font-size: 14px !important;
  }
  .homeimg{
    width:100% !important;
    position: unset !important;
    height:276px !important
  }
  .cardImage{
    height:145px !important;
  }
} */

@media screen and (max-width: 799px) {
  .about-display {
    display: block;
    padding: 0px;
  }
  .Header .header {
    display: none !important;
  }
  .navbarrrrr {
    top: unset !important;
  }
  .carousel-item img {
    height: 220px !important;
  }
  .title {
    font-size: 20px !important;
  }
  .text-typer-animation {
    font-size: 15px !important;
  }
  .homeimg {
    width: 100% !important;
    position: unset !important;
    height: 276px !important;
  }
  .cardImage {
    height: 145px !important;
  }
  .first-box {
    padding: 10px 12px;
  }
  .counts_bg{
    position: relative;
    left: 0px;
    padding-left: 0px;
    padding-right: 12px;
    margin-top: 3rem;
    height: auto;
  }
  .text-typer-animation{
    white-space:unset;
    letter-spacing: normal;
  }
  .first-box p{
    font-size: 15px;
    text-align: justify;
  }
  .card-title{
    font-size: 15px !important;
  }
 
}
