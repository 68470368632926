.products {
  border: 2px solid #1487c6;
  padding: 2rem;
  margin-top: 2rem;
  background-color: white;
}
.products1 {
  border: 2px solid #1487c6;
  padding: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
}

.products ul li {
  list-style: none;
}
.modal-content {
  border-radius: unset !important;
}
.about {
  padding: 50px;
  background-color: rgb(128, 129, 133);
  position: relative;
}

.about-desc {
  color: white;
  font-family: Roboto, sans-serif;
  line-height: 28px;
  font-size: 18px;
  text-align: justify !important;
}
.xfgfd{
    padding: 2rem;
}

.pimg{
  width: 100%;
  height: 227px;
}
@media screen and (max-width: 799px) {
  .contact-main-title {
    padding: 12px 100px;
  }
  .about {
    padding: 20px;
  }
  .about-desc {
    font-size: 15px;
    text-align: justify;
  }
  .products {
    padding: 1rem;
  }
  .products1{
    padding: 1rem;
  }
  .xfgfd{
    padding: 1rem;
  }

  .viewAllProducts{
    justify-content: left;
    display: flex;

  }
 
  .productimg {
    position: unset !important;
    width: 100% !important;
    height: 240px !important;
  }
  .pimg{
    width: 100% !important;
    height: 227px;
  }
}
