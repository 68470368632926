.aboutus li {
  font-size: 20px;
  text-align: justify;
}
.contact-form-section {
  display: flex;
  padding: 0px 37px;
  flex: 2;
}

.contact-first-title {
  font-size: 22px;
  font-weight: 500;
  padding: 20px 0 0 0;
}

.contact-main-title {
  font-weight: 500;
  font-family: system-ui;
  padding: 30px 100px;
}

.form-container {
  width: 500px;
}

.banner-logo {
  width: 100%;
  height: 300px;
}

.discount {
  background: linear-gradient(45deg, #d10f30, yellow);
  color: white;
  height: 20px;
  text-align: center;
  align-items: center;
  margin: 0px 20px;
  position: absolute;
  right: 0;
  font-weight: 500;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="file"],
input[type="password"],
select,
textarea {
  width: 100%;
  border: 0.3px solid #bdc3c7;
  box-sizing: border-box;
  resize: vertical;
}

.send-button {
  padding: 8px 14px;
  margin: 20px 0;
  border: none;
  background: #fd640c;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 20px;
}

.address-section {
  padding: 20px 70px;
  text-align: left;
}

.address-section h3 {
  color: orange;
  font-size: 20px;
}

.address-section h5 {
  font-size: 18px;
  line-height: 22px;
}

.contact-s-icon {
  display: flex;
  gap: 6px;
}

.FAQ__get__started__email {
  text-align: center;
  padding-bottom: 25px;
}

.FAQ__get__started__email > h3 {
  font-size: 18px;
  font-weight: 400;
  margin: 1rem 0;
}

.faq {
  text-align: center;
  font-size: 18px;
  background: rgb(245 245 245);
}

.faq h2 {
  font-size: 22px;
  font-weight: 500;
  padding: 21px 0 0 0;
}

.accordian {
  margin: 20px auto;
  width: 100%;
  max-width: 750px;
}

.accordian li {
  list-style: none;
  width: 100%;
  padding: 5px;
}

.accordian li label {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
  background: #808185;
  color: white;
}

.accordian li label::after {
  content: "+";
  font-size: 34px;
  position: absolute;
  right: 20px;
  transition: transform 0.5s;
}

input[type="radio"] {
  display: none;
}

.accordian .content {
  text-align: left;
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s;
}

.accordian input[TYPE="radio"]:checked + label + .content {
  max-height: 600px;
  padding: 30px 20px;
}

.accordian input[TYPE="radio"]:checked + label::after {
  transform: rotate(135deg);
}
.aboutimg{
  width: 100%;
  height: 521px;
}
.about-container{
  background-color: rgb(224, 224, 224);
  padding: 2rem;
}
.about-des{
  font-size: 20px;
  text-align: justify;
}
.inputs-row{
  display: flex;
  gap: 10px;
}
.text{
  margin:6px 0px 16px 0px;
  padding:10px;
}
.tell-us-display{
  background-color: rgb(20, 135, 198);
  padding: 2rem;

}
/* @media only screen and (min-width: 0px) and (max-width: 600px) {
 
  .aboutimg{
    height:221px !important
  }
 
} */

@media screen and (max-width:799px) {
  .aboutimg{
    width: 100%;
    height: 200px !important;
  }
  .about-container{
    padding: 1rem;
  }
  .about-des{
    font-size: 15px;
    text-align: justify;
  }
  .aboutus li {
    font-size: 15px;
    text-align: justify;
  }
  .contact-form-section{
    padding: 0 12px ;
  }
  .inputs-row{
    display: block;
  }
  .text{
    width: auto !important;
  }
  .tell-us-display{
    padding: 1rem;
  }
}
